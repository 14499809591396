
.navigation-container {
  background-color: #0096ae;
  margin: 40px 0 3px 0;
  position: relative;
  .menu {
    top: -8px;
    padding-left: 12px;
    li {
      padding: 0 12px 0 12px;
      a {
        color: #FFFFFF;
        text-transform: uppercase;
        font-weight: 700;
        padding: 9px 0 0 0;
        border-width: 4px 0 0 0;
        border-style: solid;
        border-top-color: transparent;
      }
      a.selected {
        border-width: 4px 0 0 0;
        border-style: solid;
        border-top-color: #0096ae;
      }
    }
  }

  .logo-container {
    position: relative;
    .logo {
      position: absolute;
      top: -20px;
      right: 0;
      width: 100px;
      z-index: 1;
    }
  }
}

.primary-container {
  background-color: #99d5df;

  .flyer-margins {
    margin-top: 60px;
    margin-bottom: 30px;
  }

  .primary-heading {
    font-weight: 700;
    color: #FFFFFF;
    border-width: 0 0 0 6px;
    border-style: solid;
    border-color: #0096ae;
    padding-left: 10px;
    font-size: 50px;
    line-height: 44px;
  }

  .primary-paragraph {
    color: #0096ae;
    font-weight: 700;
    margin-bottom: 30px;
    padding-left: 18px;
    line-height: 110%;
    font-size: 22px;
  }

  .orbit-container {
    margin-top: 60px;
    margin-bottom: 60px;
    width: 100%;
  }

  @media only screen and (max-width: 1023px) {
    .orbit-container{
      margin-top: 0;
      margin-bottom: 20px;
    }
  }
  @media only screen and (max-width: 40em) {
    .orbit-container{
      display: none;
    }
  }
}

.primary-paragraph {
  color: #0096ae;
  font-weight: 700;
  margin-bottom: 30px;
  padding-left: 18px;
  line-height: 110%;
  font-size: 22px;
}

.footer-container {
  background-color: #000000;
  p {
    color: #cccccc;
    margin: 10px 0 10px 0;
    padding-left: 18px;
  }
}

.margins30 {
  margin: 30px 0 30px 0;
}

.main-body {

  margin-top: 30px;
  margin-bottom: 30px;

  p {
    padding-left: 18px;
  }

  .blue {
    color: #0096ae;
  }

  .contact-header {
    color: #0096ae;
    font-weight: 700;
    margin-bottom: 15px;
    margin-top: 20px;
    padding-left: 18px;
    line-height: 110%;
    font-size: 22px;

  }
  .contact {
    margin-bottom: 5px;
  }
}

